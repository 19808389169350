.icon-circle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 2rem;
  transition: transform 0.3s ease-in-out;
}

.icon-wrapper:hover .icon-circle {
  transform: scale(1.1);
}


.icon-wrapper:hover .slide-bar {
  width: 100%;
  left: 0;
}

.menu-wrapper {
  width: 100%;
  position: fixed;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  z-index: 1;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
}

@media screen and (min-width: 768px) {
  .menu-wrapper {
    justify-content: center;
    gap: 16px;
  }
}

