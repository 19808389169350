

.marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    height: 100px;
}

.marquee {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
    white-space: nowrap;
    font-size: 30px;
    color: rgb(209, 32, 32);
}
.display-5{
    font-size: 45px;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
@media (max-width: 767.98px) { 

    .display-5{
        font-size: 10px;
    }

    .linkedin{
        font-size:5px;
    }
    .github{
        font-size:5px;

    }
    .youtube{
        font-size:5px;

    }
    .twitter{
        font-size:5px;

    }
    .facebook{
        font-size:5px;

    }
    .instagram{
        font-size:5px;

    }
    .whatsapp{
        font-size:5px;

    }
    .marquee-container {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        height: 20px;
    }
    
    .marquee {
        display: inline-block;
        padding-left: 100%;
        animation: marquee 10s linear infinite;
        white-space: nowrap;
        font-size: 8px;
        color: rgb(209, 32, 32);
    }

}

.hover-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.hover-container:hover {
    background-color: #596470;
    transform: translateY(-5px);
    color: #fff;
}

.hover-container a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.hover-container:hover a {
    color: #fff;
}
   
   
    

    


.linkedin{
    margin: 10px;
    color: #0077b5;
}
.linkedin:hover{
    color: rgb(2, 2, 2);
}

.github{
    margin: 10px;
    color: black;
}
.github:hover{
    color: rgb(255, 17, 0);
}

.youtube{
    margin: 10px;
    color: rgb(240, 6, 6);
}
.youtube:hover{
    color: rgb(15, 14, 14);
}

.twitter{
    margin: 10px;
    color: #1da1f2;
}
.twitter:hover{
    color: rgb(8, 8, 8);
}
.facebook{
    color: #1e3050;
}
.facebook:hover{
    color: #7787a3;
}

.instagram{
    margin: 10px;
    color: #fe0161;
}
.instagram:hover{
    color: rgb(10, 9, 9);
}

.whatsapp{
    margin: 10px;
    color: #42e35f;
}
.whatsapp:hover{
    color: rgb(12, 12, 12);
}
.img-fluid{
    opacity: 0.5;
}

