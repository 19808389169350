/*Header*/

.wrapper{
  display: inline-flex;
}
.wrapper .icon{
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span{
  position: relative;
  height: 60px;
  width: 60px;
  display: block;
  background:  #50cceb;
  text-align: center;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 10px 10px rgba(8, 8, 8, 0.1);

}

.wrapper .icon span i{
  font-size: 25px;
  line-height: 60px;
}
.wrapper .icon:hover span  {
  color: #fff;
}
.wrapper .icon .text{
  position: relative;
  top: 0px;
  background:  #fff;
  color: #fff;
  font-size: 20px;
  padding: 8px;
  border-radius:25px;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
} 
.wrapper .icon:hover .text{
  top :-4px;
  opacity: 1;
  pointer-events: auto;
}
.wrapper .icon .text:before{
  position: relative;
  content: "";
  height: 15px;
  width: 15px;
  background: #ffffff;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
}
.wrapper .icon:hover span,
.wrapper .icon:hover .text{
  text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}
.wrapper .home:hover span,
.wrapper .home:hover .text,
.wrapper .home:hover .text:before{
  background: #385999;
}
.wrapper .about:hover span,
.wrapper .about:hover .text,
.wrapper .about:hover .text:before{
  background: #46C1F6;
}
.wrapper .project:hover span,
.wrapper .project:hover .text,
.wrapper .project:hover .text:before{
  background: #e1306c;
}
.wrapper .resume:hover span,
.wrapper .resume:hover .text,
.wrapper .resume:hover .text:before{
  background: #333;
}
.wrapper .contact:hover span,
.wrapper .contact:hover .text,
.wrapper .contact:hover .text:before{
  background: #DE4638;
}

@media (max-width: 767.98px) { 
  .wrapper{
    display: inline-flex;
  }
  .wrapper .icon{
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .wrapper .icon span i{
    font-size: 12px;
    }
  .wrapper .icon .text{
    position: absolute;
    top: 0px;
    background:  #fff;
    color: #fff;
    font-size: 10px;
    padding: 4px;
    border-radius:10px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  } 
  .wrapper .icon:hover .text{
    top :-35px;
    opacity: 1;
    pointer-events: auto;
  }
  .wrapper .icon .text:before{
    position: absolute;
    content: "";
    height: 7px;
    width: 7px;
    background: #fff;
    bottom: -4px;
    left: 25%;
    transform: translate(-50%) rotate(45deg);
  }
  .wrapper .icon:hover span,
.wrapper .icon:hover .text{
  text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}
 }
/*Contact*/
 .form-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(248, 247, 247, 0.1);
  max-width: 100%px;
  width: 100%;
}

h2 {
  text-align: center;
  color: black;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input,
textarea {
  width: 380px;
  padding: 10px;
  border: 1px solid #2e2d2dee;
  border-radius: 4px;
  font-size: 14px;
}
.card{
  width: 300px;
}
.contact-col{
  flex-basis: 48%;
  margin-bottom: 30px;
}
.contact-col div{
  display: flex;
  align-items: centre;
  margin-bottom: 40px;
}
.contact-col div .fa{
  font-size: 28px;
  color: #f44336;
  margin: 10px;
  margin-right: 30px;
}
.contact-col div p{
  padding: 0;
}
.contact-col div h5{
  font-size: 8px;
  margin-bottom: 5px;
  color: #555;
  font-weight: 400;
}
.contact-col input, .contact-col textarea{
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.gmap-frame{
overflow: hidden;
padding-bottom: 56.25%;
position: relative;
height: 0;
}
.gmap-frame iframe{
left: 0;
top: 0;
height: 100%;
width: 100%;
position: absolute;
}

/*RESUME*/
.resume-container {
  width: 80%;
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
}

.header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #007bff;
}


.header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.section {
  margin-bottom: 20px;
}

.section h3 {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 10px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.job h4 {
  margin: 10px 0 5px;
  font-size: 18px;
  color: #333;
}

.job ul {
  list-style: disc;
  padding-left: 20px;
}

.job ul li {
  margin-bottom: 5px;
}

.skill {
  margin-bottom: 10px;
}

.skill h4 {
  margin: 0 0 5px;
}

.progress-bar {
  background-color: #007bff;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  border-radius: 5px;
}

 

/* Responsive Styles */
@media (max-width: 600px) {
  header h1 {
      font-size: 1.5em;
  }

  header p {
      font-size: 0.9em;
  }

  section h1 {
      font-size: 1.3em;
  }

  section ul li h3 {
      font-size: 1em;
  }
}
/*ABOUT*/
.font{
  font-size: larger;
}
.card-header {
  background-color: #007bff !important;
}

.card-body {
  padding: 20px;
}

.card-title {
  margin-bottom: 20px;
}

ul.list-unstyled {
  padding: 0;
  margin: 0;
}

ul.list-unstyled li {
  margin-bottom: 10px;
}
.card-img-top {
  border-bottom: 1px solid #ddd;
}
.top-nav {
  position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100vw;
}


